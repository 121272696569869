import "../index.css";
import "../styles/global/mobile.css"
import logo from "../images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Hamburger from "hamburger-react";
import x from "../images/x-symbol.png";
import dot from "../images/arrow_bar.png";
import { topFunction } from "../utils/scroll";

function DonationBanner() {
   const navigate = useNavigate();
   
   const [isOpen, setIsOpen] = useState(false);
  
   return (
      <>
         <div className="donation-header">
            <div className="hamburger">
               <div className={isOpen ? "" : "close"}>
                  <div className="column">
                     <div>
                        <button onClick={() => setIsOpen(false)}>
                           <img width="30px" height="30px" src={x} alt="x" />
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/'); topFunction() }}>
                           <img width="95px" src={logo} alt="logo" />
                        </button>
                     </div>
                     <div className="elements">
                        <button onClick={() => { setIsOpen(false); navigate('/'); topFunction() }}>
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Home</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/about'); topFunction() }}>
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>About Us</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/howto'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>How to Give</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/process'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>The Process</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/disease'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Disease & Test</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/application'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Patient Application</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/story'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Upload Your Story</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/resources'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Resources</p>
                        </button>
                        <button onClick={() => { setIsOpen(false); navigate('/privacy'); topFunction() }} className="HIDE-CONTENT-NOT-READY">
                           <img width="15px" height="15px" src={dot} alt="dot" />
                           <p>Privacy</p>
                        </button>
                     </div>
                  </div>
               </div>
               <div className={isOpen ? "" : "close"}>
                  <button onClick={() => setIsOpen(false)} />
               </div>
               <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
                  <button className="primary">DONATE</button>
               </Link>
            </div>



            <Hamburger toggled={isOpen} toggle={setIsOpen} />
         </div>
      </>
   );
}

export default DonationBanner;
