import { Link, useNavigate } from "react-router-dom";
import "../index.css";
import "../styles/footer.css";
import "../styles/global/mobile.css";
import "../styles/global/global.css";
import logo from '../images/footer logo.svg';
import threads from "../images/threads.svg";
import instagram from "../images/ig.svg";
import facebook from "../images/fb.svg";
import linkedin from "../images/linkedin.svg";
import youtube from "../images/youtube.svg";
import imageA from "../images/big blossom.svg";
import imageB from "../images/corner.svg"
import down from '../images/downarrow.svg';
import { scrollToTop, topFunction } from "../utils/scroll";
import useIsMobile from '../utils/mobile';

function Footer() {
   const navigate = useNavigate();
   const isMobile = useIsMobile();
   
   return (
      
         <div className="footer">
            <div className="bg-vdg pt-5">
               <div className="ml-15p mr-15p">
                  <div className="flex gap-10 mob-gap-0">
                     <div className="w-30 relative left">
                        <img className="footer-logo" src={logo} alt="logo"/>
                        <img className="image-a" src={imageA} alt="imageA" />
                     </div>
                     <div className="w-70 relative right">
                        <div className="navigation">
                           <div>
                              <ul>
                                 <li className="HIDE-CONTENT-NOT-READY">
                                    <button onClick={() => { navigate('/patients'); topFunction()}}>
                                       <p>Patients</p>
                                    </button>
                                 </li>
                                 <li>
                                    <button onClick={() => { navigate('/about'); topFunction()}}>
                                       <p>About Us</p>
                                    </button>
                                 </li>
                                 <li className="HIDE-CONTENT-NOT-READY">
                                    <button onClick={() => { navigate('/howto'); topFunction()}}>
                                       <p>How to Give</p>
                                    </button>
                                 </li>
                              </ul>
                              <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
                                 <button className="primary">DONATE</button>
                              </Link>
                           </div>
                        </div>
                        {
                           ( isMobile && (
                              
                                 <div className="flex center mt-4 HIDE-CONTENT-NOT-READY">
                                    <div className="w-80 flex items-center between">
                                       <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                                          <button>
                                             <img width="35px" height="41px" src={threads} alt="threads" />
                                          </button>
                                       </Link>
                                       <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                                          <button>
                                             <img width="35px" height="35px" src={linkedin} alt="linkedin" />
                                          </button>
                                       </Link>
                                       <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                                          <button>
                                             <img width="38px" height="38px" src={facebook} alt="facebook" />
                                          </button>
                                       </Link>
                                       <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                                          <button>
                                             <img width="44px" height="30px" src={youtube} alt="youtube" />
                                          </button>
                                       </Link>
                                       <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                                          <button>
                                             <img width="35px" height="35px" src={instagram} alt="instagram" />
                                          </button>
                                       </Link>
                                    </div>
                                 </div>
                              
                           ))
                        }
                        <div className="cloud">
                           <div>
                              <button onClick={scrollToTop}>
                                 <div className="flex w-100 center">
                                    <img width="24px" height="42px" src={down} alt="down" />
                                 </div>
                                 <p>RETURN TO TOP</p>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="social">
                     <div className="flex center w-30 mt-6 left">
                        <div className="w-80 flex items-center between HIDE-CONTENT-NOT-READY">
                           <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                              <button>
                                 <img width="35px" height="41px" src={threads} alt="threads" />
                              </button>
                           </Link>
                           <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                              <button>
                                 <img width="35px" height="35px" src={linkedin} alt="linkedin" />
                              </button>
                           </Link>
                           <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                              <button>
                                 <img width="38px" height="38px" src={facebook} alt="facebook" />
                              </button>
                           </Link>
                           <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                              <button>
                                 <img width="44px" height="30px" src={youtube} alt="youtube" />
                              </button>
                           </Link>
                           <Link to="https://www.google.com/" target="_blank" className="noUnderline">
                              <button>
                                 <img width="35px" height="35px" src={instagram} alt="instagram" />
                              </button>
                           </Link>
                        </div>
                     </div>
                     <div>
                        <Link to="https://www.guidestar.org/profile/shared/d30c9e9b-05b5-4cca-9fae-94a6d23d65d7" target="_blank">
                           <img src="https://widgets.guidestar.org/TransparencySeal/9346217" />
                        </Link>
                        <p className="description">Labs4Free is a 501(c)(3) nonprofit organization, EIN 46-4869516.<br/>Donations are tax-deductible.<br/><br/>©2023 Labs4Free. All rights reserved. Privacy Policy</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex end w-100 bg-vdg">
               <img width="300" height="160px" src={imageB} alt="imageB" />
            </div>
         </div>
      
   );
}

export default Footer;
