import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import './styles/global/text.css';
import './styles/global/layout.css';
import './styles/global/global.css';
import './styles/global/mobile.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
reportWebVitals();
