import '../styles/home.css';
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';
import banner from '../images/main.png';
import blossom from '../images/bullet-blossom.svg';
import flower from '../images/tube-flower.svg';
import tube from '../images/logo-icon.svg';
import cash from '../images/cash.svg';
import angel from '../images/test-angel.svg';
import diagnosis from '../images/diagnosis.svg';
import heart from '../images/icon-globe.svg';
import heartTube from '../images/icon-heart-tube.svg';
import arrow from '../images/arrow2.svg';
import father from '../images/father-son@2x.png';
import concert from '../images/concert@2x.png';
import patientWoman from '../images/girl2@2x.png';
import patientGirl from '../images/girl1@2x.png';
import patientBaby from '../images/baby1@2x.png';
import patientKid from '../images/kid1@2x.png';
import patientBoy from '../images/man1@2x.png';
import patientMan from '../images/man2@2x-normal.png';
import circle from '../images/icon-round-tubes.svg';
import time from '../images/time.svg';
import disease from '../images/icon-disease-test.svg';
import Final from '../components/Final';
import useIsMobile from '../utils/mobile';
import { DiseasesBox } from '../components/DiseasesBox';

function Home() {
   const [patient, setPatient] = useState(0);
   const isMobile = useIsMobile();
   const navigate = useNavigate();

   /* currently not in use -- can be reimplemented*/
   const [diseaseExpanded, setDiseaseExpanded] = useState(false);
   const handleDiseaseExpand = () => {
      setDiseaseExpanded(!diseaseExpanded);
   };

   return (
      <>
         <div className="intro">
            <div className="flex end items-center w-50">
               <img width="757px" height="572px" src={banner} alt="banner" />
            </div>
            <div className="flex w-50 center items-start flex-col">
               <h1 className="title text-left">
                  Be a&nbsp;<span className="c-dtur">Hero&nbsp;</span>for Others!
               </h1>
               <h4 className="subtitle text-left">Help remove financial barriers to life-saving lab tests for patients.</h4>
               <ul className="intro-bullets flex flex-col gap-6">
                  <li>
                     <div className="flex items-start gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <p>Without specialized lab tests, patients can’t access life-saving treatments.</p>
                     </div>
                  </li>
                  <li>
                     <div className="flex items-start gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <p>At $300-$15,000 per year, specialized lab testing is beyond the reach of most families.</p>
                     </div>
                  </li>
                  <li>
                     <div className="flex items-start gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <p>Early access to laboratory tests can save a patient’s life, limb or organ.</p>
                     </div>
                  </li>
                  <li>
                     <div className="flex items-start gap-4">
                        <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                        <p>Save a Life… <Link to="https://donate.labs4free.org/checkout/28627" target="_blank">Fund a Test!</Link></p>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
         <div className="info">
            <div className="top" id="t_patients">
               <img width="70px" height="70px" src={flower} alt="flower" />
               <h3 className="title">Patients</h3>
            </div>
            <div className="top" id="t_aboutus">
               <img width="70px" height="70px" src={tube} alt="tube" />
               <h3 className="title">About Us</h3>
            </div>
            <div className="top" id="t_title">
               <img width="70px" height="70px" src={cash} alt="cash" />
               <h3 className="title">How to Give</h3>
            </div>
            <div className="bottom" id="b_applynow">
               <p>Apply to have your lab test for rare diseases and chronic disorders paid for by Labs4Free - easy to complete and processed within 3 business days! Most applicants in need are approved with or without health insurance.</p>
               <Link to="mailto:tara.williams@labs4free.org">
                  <button className="primary">APPLY NOW</button>
               </Link>
            </div>
            <div className="bottom" id="b_learnmore">
               <p>Labs4Free funds laboratory tests for rare diseases and chronic disorders. We are based in Berkeley, California. We are a 501(c)3 organization which means contributions are tax deductible.</p>
               <button className="primary" onClick={() => navigate('/about')}>LEARN MORE</button>
            </div>
            <div className="bottom" id="b_donate">
               <p>No amount is too small, no amount is too big! You can give via credit card, stocks, crypto, donor advised funds like Fidelity and Schwab, IRA distributions, and you can bequeath to Labs4Free in your Will or Trust.</p>
               <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
                  <button className="primary">DONATE</button>
               </Link>
            </div>
         </div>
         <div className="video HIDE-CONTENT-NOT-READY">
            <div className="video-container">
               <iframe src="https://www.youtube.com/embed/SLfq7ly7nuI?autoplay=1&loop=1" />
            </div>
         </div>
         <div className="stats">
            <h2 className="title">Did you know:</h2>
            <div>
               <div className="bg-dg">
                  <img width="162px" height="164px" src={angel} alt="angel" />
                  <p className="description">Laboratory Medicine has gone from just Testing to Saving Lives.</p>
                  <p className="signature">Maria Salinas</p>
               </div>
               <div className="bg-tur">
                  <h5 className="title">70%</h5>
                  <p className="description">70% of today’s medical decisions depend on laboratory test results!</p>
                  <p className="signature">Center for Disease Control</p>
               </div>
               <div className="bg-hdg">
                  <h5 className="title">6.3</h5>
                  <p className="description">Rare disease patients wait on average 6.3 years before receiving a correct diagnosis.</p>
                  <p className="signature">EveryLife Foundation for Rare Diseases</p>
               </div>
               <div className="bg-hdg">
                  <h5 className="title">10,000</h5>
                  <p className="description">With over 10,000 rare diseases, it’s no surprise doctors have difficulty properly diagnosing illnesses.</p>
               </div>
               <div className="bg-dg">
                  <h5 className="title">50%</h5>
                  <p className="description">Half of rare disease patients are children and 30% will not live to see their fifth birthday.</p>
                  <p className="signature">National Organization for Rare Disorders</p>
               </div>
               <div className="bg-tur">
                  <img width="141px" height="155px" src={diagnosis} alt="diagnosis" />
                  <p className="description">Diagnostic laboratory tests eliminate the guess work in diagnosis, saving lives!</p>
               </div>
            </div>
         </div>
         <div className="mission">
            <div className="w-50 flex flex-col gap-10">
               <div className="flex gap-6 items-center">
                  <img width="123px" height="113px" src={heart} alt="heart" />
                  <h2 className="title text-left">We belive healthcare is a human right</h2>
               </div>
               <div className="mob-father none">
                  <img width="757px" height="572px" src={father} alt="father" />
               </div>
               <h4 className="subtitle text-left">Rapid tests for identifying fast moving diseases like aHUS exist. And once diagnosed there are life-saving treatments available. Yet, a shocking 80% of patients with the genetic disease, aHUS, die; in part due to lack of discovery of their disease, Chief Medical Director at Machaon Diagnostics, Doctor Bradley Lewis, explains.</h4>
               <p>Tests for rare diseases and chronic disorders cost between $300 to $15,000 per patient per year! While 90% of the U.S. population are insured, insurance companies rarely cover these specialized costs. So patients with and without insurance often can’t afford to get the tests their doctors order.<br /><br />We aim to address and draw attention to this gap in our nation’s health care system by raising funds to provide free diagnostic testing, helping save lives one patient at a time.</p>
               <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
                  <button className="secondary">
                     <img width="63px" height="81px" src={heartTube} alt="heartTube" />
                     <p>Fund a Test for Someone in Need!</p>
                     <div>
                        <img className="arrow" width="68px" height="39px" src={arrow} alt="arrow" />
                     </div>
                  </button>
               </Link>
            </div>
            <div className="flex center items-center w-50">
               <img width="757px" height="572px" src={father} alt="father" />
            </div>
         </div>
         <div className="person person-home">
            <h2 className="title HIDE-CONTENT-NOT-READY">Happy Patients</h2>
            { isMobile ? (
               <>
                  <div>
                     <button onClick={() => setPatient(0)}>
                        <div className="radius-3xl left relative">
                           <div className={`${patient === 0 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`} />
                           <img width="100%" src={patientKid} alt="patientKid" />
                        </div>
                     </button>
                     <button onClick={() => setPatient(1)}>
                        <div className="radius-3xl relative">
                           <div className={`${patient === 1 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`} />
                           <img width="100%" src={patientWoman} alt="patientWoman" />
                        </div>
                     </button>
                     <button onClick={() => setPatient(2)}>
                        <div className="radius-3xl right relative">
                           <div className={`${patient === 2 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`} />
                           <img width="100%" src={patientBoy} alt="patientBoy" />
                        </div>
                     </button>
                     <button onClick={() => setPatient(3)}>
                        <div className="radius-3xl left relative">
                           <div className={`${patient === 3 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`}>
                              <div>
                                 <p>Roger Rodriguez</p>
                                 <p>Oakland</p>
                                 <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                              </div>
                           </div>
                           <img width="100%" src={isMobile ? (patientMan) : (patientBaby)} alt="patientBaby" />
                        </div>
                     </button>
                     <button onClick={() => setPatient(4)}>
                        <div className="radius-3xl relative">
                           <div className={`${patient === 4 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`} />
                           <img width="100%" src={isMobile ? (patientBaby) : (patientMan)} alt="patientMan" />
                        </div>
                     </button>
                     <button onClick={() => setPatient(5)}>
                        <div className="radius-3xl right relative">
                           <div className={`${patient === 5 ? 'person-selected HIDE-CONTENT-NOT-READY' : ''}`} />
                           <img width="100%" src={patientGirl} alt="patientGirl" />
                        </div>
                     </button>
                  </div>
               </>
            ) : (
               <div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 1</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientKid} alt="patientKid" />
                  </div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 2</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientWoman} alt="patientWoman" />
                  </div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 3</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientBoy} alt="patientBoy" />
                  </div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 4</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientBaby} alt="patientBaby" />
                  </div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 5</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientMan} alt="patientMan" />
                  </div>
                  <div className="person-story">
                     <div className="HIDE-CONTENT-NOT-READY">
                        <p>PERSON 6</p>
                        <p>CURRENTLY INACCESSABLE</p>
                        <p>The patient's story is currently inaccessible, and we apologize for any inconvenience. Thank you for your understanding and patience.</p>
                     </div>
                     <img width="100%" src={patientGirl} alt="patientGirl" />
                  </div>
               </div>
            )}
         </div>
         <div className="goal">
            <div className="flex center items-center w-50">
               <img width="718px" height="718px" src={concert} alt="concert" />
            </div>
            <div className="w-50">
               <div className="container">
                  <div className="flex items-center gap-5 text-left">
                     <img width="135px" height="126px" src={time} alt="time" />
                     <h2 className="title">Long Term Goals</h2>
                  </div>
                  <div className="mob-goal-img none">
                     <img width="718px" height="718px" src={concert} alt="concert" />
                  </div>
                  <h4 className="subtitle text-left">Our primary goal is to reduce avoidable:</h4>
                  <ul className="goal-bullets flex flex-col gap-5">
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Emergency room visits</p>
                        </div>
                     </li>
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Severe organ damage</p>
                        </div>
                     </li>
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Loss of employment</p>
                        </div>
                     </li>
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Loss of home</p>
                        </div>
                     </li>
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Bankruptcy</p>
                        </div>
                     </li>
                     <li>
                        <div className="flex items-start gap-4">
                           <img width="23px" height="23px" src={blossom} alt="bulletBlossom" />
                           <p>Death rate</p>
                        </div>
                     </li>
                  </ul>
               </div>
               <button className="secondary HIDE-CONTENT-NOT-READY">
                  <img width="87px" height="73px" src={circle} alt="circle" />
                  <p>View list of Labs4Free tests</p>
                  <div>
                     <img className="arrow" width="68px" height="39px" src={arrow} alt="arrow" />
                  </div>
               </button>
            </div>
         </div>
         <div className="diseases">
            <img className="arrow" width="177px" height="177px" src={disease} alt="disease" />
            <h2 className="title">Diseases We Test For</h2>
            {/*
               expand menue -- already designed: <div style={{ height: diseaseExpanded ? '1153px' : '765px' }} className="disease-expand"> //377px (element) + 11px (gap) = 388px height per element

               <button onClick={handleDiseaseExpand} className={diseaseExpanded ? "desease-button desease-button-active" : "desease-button"} >
                  <p>VIEW MORE</p>
                  <div>
                     <img className={diseaseExpanded ? "desease-button-rotate" : ""} width="24px" height="42px" src={down} alt="down" />
                  </div>
               </button>
             */}
            <div className="types-content-container">
               <DiseasesBox color="bg-dg" disease="Atypical Hemolytic Uremic Anemia (aHUS)" test="aHUS Genetic Panel 3.0" frequency="5 days (M-F)" cost="$2,987" description="22 genes associated with TMAs have been sequenced and analyzed as part of this panel: ADAMTS13, C2, C3, C3AR1, C4BPA, C4BPB, CD46 (MCP), CFB, CFH, CFHR1, CFHR2, CFHR3, CFHR4, CFHR5, CFI, DGKE, INF2, MMACHC, PLG, THBD, VTN and WT1, plus the region of C5 underlying poor response to eculizumab and a specific, gain-of-function intronic variant (c.914-5T&#x3e;A) in TSEN2 linked to aHUS. In addition to picking up both known and novel single nucleotide variants, this test also detects the large deletions in CFHR3-1 and CFHR1-4. Rare, pathogenic CFH fusions are best detected using our CFH MLPA assay. Sanger sequencing is performed to confirm certain variants."/>
               <DiseasesBox color="bg-tur" disease="Complement System Activation	5 days (M-F)" test="Soluble Complement 5b-9 (sC5b-9)" frequency="&#x3c; 24 hours (7 days a week)" cost="$366" description="Chromogenic ELISA for the quantitative detection of soluble complement 5b-9, also known as the soluble membrane attack complex (sMAC)."/>
               <DiseasesBox color="bg-hdg" disease="Atypical Hemolytic Uremic Anemia (aHUS)" test="Anti-CFH Antibody" frequency="5 days (M-F)" cost="$366" description="Chromogenic ELISA for the quantitative detection of anti-complement factor H (CFH) antibodies."/>
               <DiseasesBox color="bg-dg" disease="Atypical Hemolytic Uremic Anemia (aHUS)" test="CFH Region Deletion/Duplication Analysis by MLPA" frequency="5 days (M-F)" cost="$2,987" description="This test looks for deletions or duplications within the CFH, CFHR1, CFHR2, CFHR3, and CFHR5 genes by multiplex ligation-dependent probe amplification (MLPA), a method with much higher resolution than next-generation sequencing or microarrays."/>
               <DiseasesBox color="bg-tur" disease="C3 Glomerulopathy (C3G)" test="C3 Glomerulopathy (C3G) Genetic Panel" frequency="5 days (M-F)" cost="$366" description="C3G comprises a group of rare kidney diseases caused by complement dysregulation. Genes sequenced: CFB, CFH, CFI, MCP, C3, CFHR5. CFHR5 is checked for the Cypriot duplication by RTPCR. Sanger sequencing may be used to confirm variants as needed."/>
               <DiseasesBox color="bg-hdg" disease="Thrombotic Microangiopathic Anemia (TMA)" test="TMA-Complete Genetic Panel 3.0" frequency="5 days (M-F)" cost="$366" description="22 genes associated with TMAs have been sequenced and analyzed as part of this panel: ADAMTS13, C2, C3, C3AR1, C4BPA, C4BPB, CD46 (MCP), CFB, CFH, CFHR1, CFHR2, CFHR3, CFHR4, CFHR5, CFI, DGKE, INF2, MMACHC, PLG, THBD, VTN and WT1, plus the region of C5 underlying poor response to eculizumab and a specific, gain-of-function intronic variant (c.914-5T&#x3e;A) in TSEN2 linked to aHUS. In addition to picking up both known and novel single nucleotide variants, this test also detects the large deletions in CFHR3-1 and CFHR1-4. Rare, pathogenic CFH fusions are best detected using our CFH MLPA assay. Sanger sequencing is performed to confirm certain variants."/>
               <DiseasesBox color="bg-hdg" disease="Thrombotic Thrombocytopenic Purpura (TTP)" test="ADAMTS13" frequency="5 days (M-F)" cost="$366" description="Chromogenic ELISA for the quantitative determination of ADAMTS13 activity in human plasma. ADAMTS13 is an enzyme that specifically cleaves large von Willebrand Factor multimers, which induce platelet thrombus formation under high shear stress. If the activity of ADAMTS13 is lowered for some reason, unusually large VWF multimers may accumulate."/>
            </div>
         </div>
         <Final />
      </>
   );
}

export default Home;
