import { Link } from "react-router-dom";
import "../index.css";
import "../styles/footer.css";
import "../styles/global/mobile.css";
import "../styles/global/global.css";
import heartTubeDark from '../images/icon-heart-test.svg';
import apply from "../images/icon-apply@2x.png";

function Final() {
   return (

        <div className="final-container">
        <div className="final">
          <div>
            <img width="100px" height="129px" src={heartTubeDark} alt="heartTubeDark" />
            <h3>Fund a Test</h3>
          </div>
          <p>Your generous donations are what makes this work. Fund a test for someone in need now.</p>
          <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
            <button className="primary">DONATE</button>
          </Link>
        </div>
        <div className="final">
          <div>
            <img width="100px" height="129px" src={apply} alt="apply" />
            <h3>Apply Now</h3>
          </div>
          <p>Patient applications are simple to complete and are usually approved with 3 business days.</p>
          
          <Link to="mailto:tara.williams@labs4free.org">
            <button className="primary">APPLY</button>
          </Link>
        </div>
      </div>
   );
}

export default Final;
