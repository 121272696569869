import '../styles/home.css';
import skrill from '../images/icon-skrill.svg';
import funk from '../images/icon-funk.svg';
import tuber from '../images/icon-tuber.svg';
import frequency from '../images/icon-frequency.svg';

export function DiseasesBox(
    props: Readonly<{
        color?: string;
        disease?: string;
        test?: string;
        frequency?: string;
        cost?: string;
        description?: string;
    }>
) {
    return (
        <div className={props.color}>
            <div className="flex between items-center">
                <div className="flex gap-5 items-center">
                    <img width="56px" height="56px" src={funk} alt="funk" />
                    <h6 className="title">{props.disease}</h6>
                </div>
                <button className="primary HIDE-CONTENT-NOT-READY">LEARN MORE</button>
            </div>
            <div className="types-items-container">
                <div>
                    <img width="26px" height="43px" src={tuber} alt="tuber" />
                    <div>
                        <p className="top-text">Test Name</p>
                        <p className="bottom-text"><u>{props.test}</u></p>
                    </div>
                </div>
                <div>
                    <img width="39px" height="39px" src={frequency} alt="frequency" />
                    <div>
                        <p className="top-text">Frequency</p>
                        <p className="bottom-text">{props.frequency}</p>
                    </div>
                </div>
                <div className="HIDE-CONTENT-NOT-READY">
                    <img width="54px" height="40px" src={skrill} alt="skrill" />
                    <div>
                        <p className="top-text">Cost per Test</p>
                        <p className="bottom-text">{props.cost}</p>
                    </div>
                </div>
            </div>
            <p className="description">{props.description}</p>
        </div>
    );
}
