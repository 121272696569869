import { Link, useNavigate } from "react-router-dom";
import heart from "../images/icon-globe.svg";
import logo from "../images/logo.svg";
import arrow from "../images/arrow.svg";
import DonationBanner from "./DonationBanner";
import "../styles/header.css";
import "../index.css";
import { useState } from "react";
import useIsMobile from '../utils/mobile';

function Header() {
   const [navExpand, setNavExpand] = useState(false);
   const handleNavHeader = () => {
      setNavExpand(!navExpand)
   };

   const navigate = useNavigate();
   const isMobile = useIsMobile();

   return (
      <>
         <DonationBanner />

         <div className="w-100">
            <div className="p-2 bg-dg">
               <div className="flex center gap-3">
                  <img width="26px" height="24px" src={heart} alt="heart" />
                  <p className="slogan">At Labs4Free, we believe healthcare is a human right.</p>
                  <img width="26px" height="24px" src={heart} alt="heart" />
               </div>
            </div>
            <div className="navbar">
               {!isMobile && (
                  <>
                     <button className="flex center" onClick={() => navigate('/')}>
                        <img width="220px" src={logo} alt="logo" />
                     </button>

                     <div className="flex items-center">
                        <ul>
                           <li className="HIDE-CONTENT-NOT-READY">
                              <button className="expand">
                                 <img width="10px" height="17px" src={arrow} alt="arrow" />
                                 <p>Patients</p>
                                 <ul className="flex">
                                    <li>
                                       <button onClick={() => navigate('/process')}>
                                          <p>The Process</p>
                                       </button>
                                    </li>
                                    <li>
                                       <button onClick={() => navigate('/disease')}>
                                          <p>Disease & Test</p>
                                       </button>
                                    </li>
                                    <li>
                                       <button onClick={() => navigate('/patient')}>
                                          <p>Patient Application</p>
                                       </button>
                                    </li>
                                    <li>
                                       <button onClick={() => navigate('/story')}>
                                          <p>Upload Your Story</p>
                                       </button>
                                    </li>
                                    <li>
                                       <button onClick={() => navigate('/resources')}>
                                          <p>Resources</p>
                                       </button>
                                    </li>
                                    <li>
                                       <button onClick={() => navigate('/piracy')}>
                                          <p>Privacy</p>
                                       </button>
                                    </li>
                                 </ul>
                              </button>

                           </li>
                           <li>
                              <button onClick={() => navigate('/about')}>
                                 <img width="10px" height="17px" src={arrow} alt="arrow" />
                                 <p>About Us</p>
                              </button>
                           </li>
                           <li  className="HIDE-CONTENT-NOT-READY">
                              <button onClick={() => navigate('/howto')}>
                                 <img width="10px" height="17px" src={arrow} alt="arrow" />
                                 <p>How to Give</p>
                              </button>
                           </li>
                        </ul>
                        <Link to="https://donate.labs4free.org/checkout/28627" target="_blank" className="noUnderline">
                           <button className="primary">DONATE</button>
                        </Link>
                     </div>
                  </>
               )}
            </div>
         </div>
      </>
   );
}

export default Header;
